<template>
  <div style="height: 60px"></div>
  <MainSize style="display: flex;flex-direction: column;justify-content: center;align-items: center">
    <div class="xz article" style="font-size: .6rem">City Hyde Park Apartments</div>
    <div class="article" style="font-size: .3rem;color: #333333">5105 S Harper Ave, Chicago, IL 60615</div>
  </MainSize>
  <MainSize style="display: flex;flex-direction: column;justify-content: center;align-items: center">
    <img class="article t_img"  src="../../assets/c/g06b.jpg" style="width: 6rem;margin: 0 2rem;height:2rem;object-fit: cover">
    <div class="t_kuai" style="width: 6rem">
      <div class="article t_title" style="font-size: .2rem;margin: 20px 0">Architect:</div>
      <div class="article t_msg" style="font-size: .15rem">Studio Gang</div>
      <div class="article t_title" style="font-size: .2rem;margin: 20px 0">Status:</div>
      <div class="article t_msg" style="font-size: .15rem">Completed in 2016</div>
      <div class="article t_title" style="font-size: .2rem;margin: 20px 0">Description:</div>
      <div class="article t_msg" style="font-size: .12rem">City Hyde Park is a visionary mixed-use fifteenstory tower with 80 units at the heart of one of Chicago's most vibrant neighborhoods. In addition to a wide variety of community amenities, its design provides unparalleled views of the skyliine and Lake Michigan, fostering connections to the neighborhood and city beyond. The building's innovative concrete panel structure pirouettes from one floor to the next resulting in a vertical array of bays, balconies, and sunshades that frame dynamic interior and exterior spaces. With outdor gardens, a fitness center, and close proximity to world-class parks, recreational venues, and cultural offerings, City Hyde Park is a community with wellness at its core.</div>
    </div>
  </MainSize>
  <img class="article" src="../../assets/c/g06b.jpg" style="width: 8rem;margin: 20px 1rem">
  <img class="article" src="../../assets/c/g02b.jpg" style="width: 8rem;margin: 20px 1rem">
  <img class="article" src="../../assets/c/g03b.jpg" style="width: 8rem;margin: 20px 1rem">
  <img class="article" src="../../assets/c/g05b.jpg" style="width: 8rem;margin: 20px 1rem">
  <img class="article" src="../../assets/c/g09b.jpg" style="width: 8rem;margin: 20px 1rem">

  <div class="xz" style="display: flex;flex-direction: row;align-items: center;justify-content: center;font-size: 0.3rem;margin: 30px 0">
    RELATED PROJECTS
  </div>
  <div class="swiper mySwiper" style="margin-bottom: 30px">
    <div class="swiper-wrapper">
      <div class="swiper-slide" :key="index" v-for="(item,index) in list" @click="$emit('fclick',index)">
        <img :src="item.img1" style="width: 100%;height: 100%;object-fit: cover">
      </div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
  <footerB/>
</template>

<script>
import footerB from "@/components/footer";
import MainSize from '@/components/MainSize.vue'
export default {
  name: "list_1",
  components: {
    footerB,
    MainSize
  },
  data(){
    return {
      list:[
        {img1:require("../../assets/shop/City Hyde Park Apartments/g05b.jpg"),img2:require("../../assets/shop/City Hyde Park Apartments/g09b.jpg"),title:"City Hyde Park Apartments",msg:"5105 S Harper Ave, Chicago, IL 60615",type:true},
        {img1:require("../../assets/so/1.png"),img2:require("../../assets/so/2.png"),title:"Solstice on the Park Apartments",msg:"1616 E 56th St,Chicago,IL 60636",type:true},
        {img1:require("../../assets/shop/St. Regis Chicago/Sky-Longe_St.-Regis-Chicago-scaled.jpg"),img2:require("../../assets/shop/St. Regis Chicago/Sky-Terrace-2-scaled.jpg"),title:"St.Regis Chicago",msg:"363 E Wacker Dr, Chicago, IL 60601",type:true},
        {img1:require("../../assets/shop/700 N. 4th St/exterior-drone.jpg"),img2:require("../../assets/shop/700 N. 4th St/grove.jpg"),title:"700 N 4th St",msg:"Phoenix, AZ 85004",type:true},
        {img1:require("../../assets/image1/5.jpg"),img2:require("../../assets/image1/5.jpg"),title:"800 Broadway",msg:"San Diego, CA 92101",type:true},
        {img1:require("../../assets/image1/6.jpeg"),img2:require("../../assets/shop/Hampton Inn/9b8c7efa6150efcbd9d03eade785fe9a3ccc0705.jpeg"),title:"Hampton Inn & Suites Chicago-Burr Ridge",msg:"100 Harvester Dr,Burr Ridge,IL 60527",type:true},
        {img1:require("../../assets/image1/7.jpeg"),img2:require("../../assets/image1/7.jpeg"),title:"Four Points By Sheraton Milwaukee Airport",msg:"5311 S Howell Ave,Milwaukee,WI 53207",type:true},
        {img1:require("../../assets/shop/Hyatt Place Chicago/Hyatt-Place-Chicago-OHare-Airport-P004-Exterior-Shuttle.16x9.jpg"),img2:require("../../assets/shop/Hyatt Place Chicago/Hyatt-Place-Chicago-OHare-Airport-P016-Exterior-Outside-Views.16x9.jpg"),title:"Hyatt Place Chicago/O'Hare Airport",msg:"6810 Mannheim Road,Rosemont,IL 60018",type:true},
        {img1:require("../../assets/ne/WX20221010-183133.png"),img2:require("../../assets/ne/WX20221010-183212.png"),title:"NEMA Chicago",msg:"1210 S Indiana Ave, Chicago, IL 60605",type:true},
      ]
    }
  },
  mounted() {
    this.$nextTick(()=>{
      this.handleScroll();
      // eslint-disable-next-line no-undef
       new Swiper(".mySwiper", {
         loop:true,
        slidesPerView: 3,
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
         autoplay: {
           delay: 2500,
           disableOnInteraction: false,
         },
      });
    })
  },
  methods:{
    handleScroll () {
      let scrollTop =  document.documentElement.scrollTop ;
      scrollTop=scrollTop+document.documentElement.clientHeight;
      let articles =  document.getElementsByClassName("article");
      for (let i = 0 ;i<articles.length ;i++){
        if(scrollTop>articles[i].offsetTop&&articles[i].style.opacity!=1){
          articles[i].style.opacity = 1;
        }
      }
    }
  }
}
</script>

<style scoped>
.swiper {
  width: 8rem;
  height: 1.5rem;
  margin: 0 1rem;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 990px){
  .t_img{
    width: 8rem !important;
  }
  .t_kuai{
    width: 8rem !important;
  }
  .t_title {
    font-size: 0.4rem !important;
  }
  .t_msg{
    font-size: 0.3rem !important;
  }
}
</style>