<template>
  <div style="height: 60px"></div>
  <div style="width: 8rem;margin:.5rem 1rem">
    <div class="title article" style="text-align: left;font-family:'Lato'">PROJECTS</div>
    <div class="f_title article" style="text-align: left;font-family:'Lato'">TURN YOUR DESIGN INTO REALITY</div>
    <div style="display: flex;flex-direction: row;justify-content: space-between;align-items: center;flex-wrap:wrap ">
      <div class="itemImgList article" v-for="(item,index) in list" :key="index" @click="$emit('fclick',index)">
        <img :src="item.type?item.img1:item.img2" @mouseover="item.type=false" @mouseout="item.type=true" class="itemImgListImg" style="">
        <div class="atitle" style="font-family:'Lato'">{{ item.title }}</div>
        <div class="ftsatitle" style="font-size: 0.10rem;
  color: #333333;">{{ item.msg }}</div>
      </div>

    </div>
  </div>
  <footer-b/>
</template>

<script>
import footerB from "@/components/footer";

export default {
  name: "main-2",
  components: {
    footerB
  },
  data(){
    return {
      list:[
        {img1:require("../assets/shop/City Hyde Park Apartments/g05b.jpg"),img2:require("../assets/shop/City Hyde Park Apartments/g09b.jpg"),title:"City Hyde Park Apartments",msg:"5105 S Harper Ave, Chicago, IL 60615",type:true},
        {img1:require("../assets/image1/21.png"),img2:require("../assets/so/2.png"),title:"Solstice on the Park Apartments",msg:"1616 E 56th St, Chicago, IL 60636",type:true},
        {img1:require("../assets/shop/St. Regis Chicago/Sky-Longe_St.-Regis-Chicago-scaled.jpg"),img2:require("../assets/shop/St. Regis Chicago/Sky-Terrace-2-scaled.jpg"),title:"St.Regis Chicago",msg:"363 E Wacker Dr, Chicago, IL 60601",type:true},
        {img1:require("../assets/shop/700 N. 4th St/exterior-drone.jpg"),img2:require("../assets/shop/700 N. 4th St/grove.jpg"),title:"700 N 4th St",msg:"Phoenix, AZ 85004",type:true},
        {img1:require("../assets/logo1/800un.png"),img2:require("../assets/image1/5.jpg"),title:"800 Broadway",msg:"San Diego, CA 92101",type:true},
        {img1:require("../assets/image1/6.jpeg"),img2:require("../assets/shop/Hampton Inn/9b8c7efa6150efcbd9d03eade785fe9a3ccc0705.jpeg"),title:"Hampton Inn & Suites Chicago-Burr Ridge",msg:"100 Harvester Dr, Burr Ridge, IL 60527",type:true},
        {img2:require("../assets/sky/WX20221010-225201@2x.png"),img1:require("../assets/sky/WX20221010-225126@2x.png"),title:"Skyline Tower",msg:"Long Island City, NY",type:true},
        {img1:require("../assets/shop/Hyatt Place Chicago/Hyatt-Place-Chicago-OHare-Airport-P004-Exterior-Shuttle.16x9.jpg"),img2:require("../assets/shop/Hyatt Place Chicago/Hyatt-Place-Chicago-OHare-Airport-P016-Exterior-Outside-Views.16x9.jpg"),title:"Hyatt Place Chicago/O'Hare Airport",msg:"6810 Mannheim Road, Rosemont, IL 60018",type:true},
        {img1:require("../assets/ne/WX20221010-183133.png"),img2:require("../assets/ne/WX20221010-183212.png"),title:"NEMA Chicago",msg:"1210 S Indiana Ave, Chicago, IL 60605",type:true},
      ]
    }
  },
  mounted() {
    this.$nextTick(()=>{
      this.handleScroll();
    })
  },
  methods:{
    handleScroll () {
      let scrollTop =  document.documentElement.scrollTop ;
      scrollTop=scrollTop+document.documentElement.clientHeight;
      let articles =  document.getElementsByClassName("article");
      for (let i = 0 ;i<articles.length ;i++){
        if(scrollTop>articles[i].offsetTop&&articles[i].style.opacity!=1){
          articles[i].style.opacity = 1;
        }
      }
    }
  }
}
</script>

<style scoped>
.atitle{
  color: #333333;
  font-size: 0.14rem;
  line-height: 0.17rem;
}
.itemImgListImg{
  width: 100%;height: 1.5rem;
  object-fit: cover;
}
.itemImgList{
  margin-top: 30px;width: 2.5rem;
}
.buttons{
  display: inline-block;
  padding:0.15rem 0.2rem;
  letter-spacing: .03rem;
  background: #e6c059;
  color: #fff;
  font-size: 0.2rem;
}
.timeLineMsg{
  font-size: 0.14rem;
  color: #333;
}
.timeLineFTitle{
  font-size: 0.14rem;
  font-weight: 700;
  color: #333;
  margin: 5px 0;
}
.timelineCircle{
  background: #e6c059;border-radius: 0.8rem;width: 0.8rem;height: 0.8rem;margin: 0 0.2rem
}
.timelineTitle{
  font-size: 0.16rem;letter-spacing: 0.05rem
}
.timelineItem{
  width: 3.95rem;display: flex;flex-direction: row;padding: 10px 0;
  align-items: center;


}

@media (max-width: 990px){
  .ftsatitle{
    font-size: 0.3rem !important;
  }
  .atitle{
    font-size: 0.6rem;
    line-height: 0.9rem;
  }
  .itemImgListImg{
    height: 3rem;
  }
  .timelineItem{
    width: 7.9rem;display: flex;flex-direction: row;padding: 10px 0;
    align-items: center;
  }
  .timelineCircle{
    background: #e6c059;border-radius: 2rem;width: 2rem;height: 2rem;margin: 0 0.2rem
  }
  .itemImgList{
    width: 8rem;
  }
  .title{
    font-size: 0.3rem !important;
    line-height: 0.45rem !important;
  }
  .f_title{
    font-size: 0.5rem !important;
    line-height: 0.75rem !important;
  }
}
.timeLine{
  font-size: 0.18rem;
  letter-spacing: .05rem;
  font-weight: 700;
  margin-top: 50px;
}
.main2img{
  height: 450px;
  background: #42b983;
}
.main2img > img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.main2body{
  margin:60px 1.5rem;
  width: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title{
  font-size: 0.1rem;
  text-transform: uppercase;
  letter-spacing: .02rem;
}
.f_title{
  text-align: center;
  letter-spacing: .01rem;
  font-size: 0.18rem;
  color: #ccad60;
  margin: 20px 0;
}
.msg{
  font-size: 0.14rem;
  color: #333;
  margin-bottom: 30px;
}
.main2content{
  background-image: linear-gradient(180deg,hsla(0,0%,95.1%,.9),
  hsla(0,0%,95.1%,.9)),
  url("../assets/image1/21.png");
  background-size: cover;
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>