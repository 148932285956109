<template>
  <div style="background: #32332f;    color: hsla(0,0%,100%,.3);text-align: center;padding: 16px;font-size: 20px;line-height: 20px">
    <span style="font-family:'Lato'">COPYRIGHT</span> © <span style="font-family:'Lato';font-size: 25px">2022 21</span><span style="font-family:'Lato'">ST CENTURY US SINO SERVICE. ALL RIGHTS RESERVED.</span>
  </div>
</template>
<script>
export default {
  name: "footerB"
}
</script>
<style scoped>

</style>