<template>
  <div>
    <div class="main">
      <div class="common" style="position: relative">
        <img src="../assets/logo.png" style="height: 50px">
        <div class="showFlex" style="position: absolute;left: 60px;top:18px;white-space: nowrap;font-size: 14px;font-weight: 700 ">21ST CENTURY US SINO SERVICE</div>
      </div>
      <div class="buttonList">
        <div style="font-family:'Lato'" :class="xIndex==index?'xz':''" v-for="(item,index) in list" :key="index" @click="setClick( index,item)">{{ item.name }}</div>
      </div>
      <div class="common right-common" >
        <div class="button" @click="xClick=!xClick">
          <img src="../assets/liebiaoshitucaidan.png" style="height: 100%">
        </div>
      </div>
    </div>
    <div class="popUp" v-show="xClick">
      <div style="font-family:'Lato'" :class="xIndex==index?'xz':''" v-for="(item,index) in list" :key="index" @click="setClick( index,item)">{{ item.name }}</div>
    </div>
  </div>
</template>
<script>
import main1 from './main1.vue'
import main2 from './main2.vue'
import main3 from './main3.vue'
import main4 from './main4.vue'
export default {
  name: "HeaderTitle",
  props:["xIndex"],
  data(){
    return {
      xClick:false,
      list:[
        {name:"HOME",componted:main1},
        {name:"ABOUT",componted:main2},
        {name:"OUR SERVICES",componted:main3},
        {name:"PROJECTS",componted:main4},
      ]
    }
  },
  mounted() {
    this.$emit("load",this.list[0])
  },
  methods:{
    setClick(index,item){
      this.xClick =false;
      this.$emit('indexClick',index,item)
    }
  }
}
</script>
<style scoped>

  .button{
    width: 50px;
    height: 50px;
    display: none;
  }
  .main{
    align-items: center;
    justify-content:space-between;
    display: flex;
    flex-direction: row;
    height: 60px;
    background: #f4f6f5;
    padding: 0 50px;
  }
  .common{
    min-width: 100px;
  }
  .right-common{
    align-items: center;
    justify-content:flex-end;
    display: flex;
    flex-direction: row;
  }
  .buttonList{
    display: flex;
    flex-direction: row;
    color: #333;
  }
  .buttonList > div{
    padding: 8px;
    cursor: pointer;
    font-size: 22px;
  }
  .popUp{
    background: #f4f6f5;
    display: none;
    flex-direction: column;
    align-items: center;
  }
  .popUp>div{
    font-size: 22px;
    padding: 8px;
  }
  @media (max-width: 990px){
    .buttonList{
      display: none;
    }
    .button{
      display: block;
    }
    .popUp{
      display: flex;
    }
  }
</style>