<template>
  <div style="height: 60px"></div>
  <div class="main2img article">
    <img src="../assets/img2.jpeg">
  </div>
  <div class="main2body article">
    <div class="title xz">21ST CENTURY US SINO SERVICE</div>
    <div class="f_title xz" style="font-family:'Lato'">Delivering Excellence in All We Do</div>
    <div class="msg xz" style="text-align: center">
      Architectural Design and Permitting<br>
      Building Information Modeling<br>
      Budgeting and ScheduleingConsulting<br>
      Project Management<br>
      Manufacturer-Window, Cabinetry, Blinds, Floor, Tile</div>
    <div class="timeLine xz" style="margin-bottom: 50px;font-family:'Lato'">TEAM</div>
    <div class="showFlex article" style="width: 8rem;flex-direction: row;justify-content: space-between;" :key="index" v-for="(tiem,index) in teamList">
      <div class="timelineItem">
        <div style="width: 2.75rem" v-show="index%2==0">
          <div class="xz timelineTitle"  style="font-family:'Lato'">{{ tiem.name }}</div>
          <div class="timeLineFTitle">{{ tiem.t }}</div>
          <div class="timeLineMsg">{{ tiem.m }}</div>
        </div>
        <div class="timelineCircle" v-show="index%2==0">
          <img :src="tiem.img" style="width: 100%;height: 100%">
        </div>
      </div>
      <div style="width: 0.01rem;background: #e6c059;"></div>
      <div class="timelineItem">
        <div class="timelineCircle" v-show="index%2==1">
          <img :src="tiem.img" style="width: 100%;height: 100%">
        </div>
        <div style="width: 2.75rem" v-show="index%2==1">
          <div class="xz timelineTitle" style="font-family:'Lato'">{{ tiem.name }}</div>
          <div class="timeLineFTitle">{{ tiem.t }}</div>
          <div class="timeLineMsg">{{ tiem.m }}</div>
        </div>
      </div>
    </div>

    <div class="noneFlex article" style="width: 8rem;flex-direction: column;justify-content: space-between;" :key="index" v-for="(tiem,index) in teamList">
      <div class="timelineItem">
        <div class="timelineCircle">
          <img :src="tiem.img" style="width: 100%;height: 100%">
        </div>
        <div style="width: 5.4rem">
          <div class="xz timelineTitle" style="font-family:'Lato'">{{ tiem.name }}</div>
          <div class="timeLineFTitle">{{ tiem.t }}</div>
          <div class="timeLineMsg">{{ tiem.m }}</div>
        </div>
      </div>
    </div>

  </div>
  <div class="main2content ">
    <div class="title xz" style="font-family:'Lato'">DESIGN & CRAFTSMANSHIP</div>
    <div class="f_title" style="font-family:'Lato'">Your American<br>Partner in Design</div>
    <div class="msg">
      21st Century is a sophisticated and experienced general contrating, construction management, and development firm. The company is led by a team of entrepreneurial and talented individuals that are bound together by a steadfast commitment to their core values of respect, integrity, courage, passion, humility and trust.


    </div>
    <div class="msg">
      At 21st Century, we specialize in providing professional personal & custom solutions and project management services for commercial and personal residential projects. Whether your are planning a renovation or are at planning a new construction, we are here to help you from planning, permotting, budgeting, project management, and seeing the project through.
    </div>

  </div>
  <div class="article" style="display: flex;flex-direction: row">
    <div style="width: 5rem;">
      <img src="../assets/image1/1.jpeg" style="width: 100%;height: 100%;object-fit: cover">
    </div>
    <div style="width: 5rem;display: flex;align-items: center;flex-direction: column;justify-content: center">
      <div style="padding: 8% 5% ">
        <div class="title" style="font-family:'Lato'">COMMUNITY PARTNERSHIPS</div>
        <div class="f_title" style="text-align: left;font-family:'Lato'" >BUILD A BETTER WORLD</div>
        <div class="msg">
          21st Century was established in 2000, and offering variety construction services in terms of Architectural Design, Site Analysis, Feasibility Studies, Permit Application, General Contracting, Development, and Manufacturer, etc. We habdle varous industrial, distribution, manufactiring, office, retail, multi-family projects,




           </div>
        <div class="msg">
          We are a leader in providing value-added construction services to our customers by creating successful partnership with them throughout the construction preconstruction process and construction process. Our pledge is to establush lasting relationships with our customers by exceeding their expectations and gaining their trust through exceptional performance.


         </div>
      </div>
    </div>
  </div>
  <footer-b/>
</template>

<script>
import footerB from './footer.vue'
export default {
  name: "main-2",
  data(){
    return {
      teamList:[
        {img:require("../assets/headPortrait/11.jpeg"),name:"Jimmy Lee",t:"PRESIDENT",m:"Jimmy founded the company in 2000, and has been in \n" +
              "this industury for 21 years. He is managing operations. \n" +
              "Also, he ownes a window& door company manufacturing \n" +
              "windows and doors that services greater Chicago area \n" +
              "over 15 years."},
        {img:require("../assets/headPortrait/img.png"),name:"Joyce Zhou",t:"DESIGN MANAGER",m:"Joyce joined as a Design Manager. Previous to this, she \n" +
              "was associate with an architectural firm and designed \n" +
              "various projects i.e., multi-family, custom house, senior \n" +
              "living, and school, etc."},
        {img:require("../assets/headPortrait/3.jpeg"),name:"Yang Li",t:"PROJECT MANAGER",m:"Yang joined as a Project Manager to manager both \n" +
              "preconstruction, and construction progress. Previous to \n" +
              "this, he was Project Manager with a top General \n" +
              "Contractor firm and delivered five (5) high rise mixed-use \n" +
              "buildings."},
        {img:require("../assets/headPortrait/4.png"),name:"Donna Pilot",t:"PROJECT MANAGER",m:"Donna joined as a Project Manager to manager both \n" +
              "preconstruction, and construction progress. Previous to \n" +
              "this, he was Project Manager with Chicago Housing \n" +
              "Authority delivered over 100 million dollar projects."},
      ]
    }
  },
  components: {
    footerB
  },
  mounted() {
    this.$nextTick(()=>{
      this.handleScroll();
    })
  },
  methods:{
    handleScroll () {
      let scrollTop =  document.documentElement.scrollTop ;
      scrollTop=scrollTop+document.documentElement.clientHeight;
      let articles =  document.getElementsByClassName("article");
      for (let i = 0 ;i<articles.length ;i++){
        if(scrollTop>articles[i].offsetTop&&articles[i].style.opacity!=1){
          articles[i].style.opacity = 1;
        }
      }
    }
  }
}
</script>

<style scoped>
.buttons{
  display: inline-block;
  padding:0.15rem 0.2rem;
  letter-spacing: .03rem;
  background: #e6c059;
  color: #fff;
  font-size: 0.2rem;
}
.timeLineMsg{
  font-size: 0.12rem;
  line-height: 0.17rem;
  color: #333;
}
.timeLineFTitle{
  font-size: 0.12rem;
  line-height: 0.17rem;
  color: #333;
  margin: 5px 0 0.1rem;
}
.timelineCircle{
  background: #666666;border-radius: 0.8rem;width: 0.8rem;height: 0.8rem;margin: 0 0.2rem;
  overflow: hidden;
}
.timelineCircle >img{
  object-fit: cover;
}
.timelineTitle{

  font-weight: 700;
  font-size: 0.3rem !important;
  letter-spacing: 0.05rem;
}
.timelineItem{
  width: 3.95rem;display: flex;flex-direction: row;padding: 10px 0;
  align-items: center;


}

@media (max-width: 990px){
  .title{
    font-size: 0.2rem !important;
  }
  .f_title{
    font-size: 0.45rem !important;
  }
  .msg{
    font-size: .3rem !important;
    line-height: .5rem !important;
  }
  .timeLine{
    font-size: .45rem !important;
  }
  .timeLineFTitle{
    font-size: 0.2rem;
    font-weight: 700;
  }
  .timelineTitle{
    font-size: 0.5rem !important
  }
  .timeLineMsg{
    font-size: 0.2rem;
    line-height: 0.4rem;
  }
  .timelineItem{
    width: 7.9rem;display: flex;flex-direction: row;padding: 10px 0;
    align-items: center;
  }
  .timelineCircle{
    background: #e6c059;border-radius: 2rem;width: 2rem;height: 2rem;margin: 0 0.2rem
  }
}
.timeLine{
  font-size: 0.3rem;
  letter-spacing: .2rem;
  font-weight: 700;
  margin-top: 50px;
}
.main2img{
  height: 450px;
  background: #42b983;
}
.main2img > img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.main2body{
  margin:60px 1.5rem;
  width: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title{
  font-size: 0.12rem;
  text-transform: uppercase;
  letter-spacing: .05rem;
}
 .f_title{
  text-align: center;
  letter-spacing: .03rem;
  font-size: 0.24rem;
  color: #ccad60;
  margin: 20px 0;
}
 .msg{
  font-size: 0.12rem;
  line-height: 0.2rem;
  color: #333;
  margin-bottom: 30px;
}
.main2content{
  background-image: linear-gradient(180deg,hsla(0,0%,95.1%,.9),
  hsla(0,0%,95.1%,.9)),
  url("../assets/image1/21.png");
  background-size: cover;
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>