<template>
  <div class="mainSize" :style="{'height':clientHeight+'px'}">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MainSize",
  data(){
    return {
      clientHeight:document.documentElement.clientHeight-60
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize) // 监听页面滚动
  },
  methods:{
    handleResize(){
      this.clientHeight = document.documentElement.clientHeight-60;
    }
  }
}
</script>

<style scoped>
.mainSize{
  width: 100%;
}
</style>