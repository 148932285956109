<template>
  <div style="height: 60px"></div>
  <MainSize style="display: flex;flex-direction: column;justify-content: center;align-items: center">
    <div class="xz article" style="font-size: .6rem;text-align: center">NEMA Chicago</div>
    <div class="article" style="font-size: .3rem;color: #333333">1210 S Indiana Ave, Chicago, IL 60605</div>
  </MainSize>
  <img class="article" src="../../assets/ne/5d642363b1c5959d6243576b_NEMA-Chicago-Signature-Bath-sm_4bf6e70cc1b9c69ccfe358b3cf2de357.jpeg" style="width: 8rem;margin: 20px 1rem">
  <img class="article" src="../../assets/ne/5d642363b1c595791543576a_1200SI_SignatureBath_4bf6e70cc1b9c69ccfe358b3cf2de357.jpeg" style="width: 8rem;margin: 20px 1rem">
  <img class="article" src="../../assets/ne/5f61426fa3a233cace077935_NEMA_5109_NJP_04-NEW_web_1d97aa78be936f86af70ab3e46744e9d_1d97aa78be936f86af70ab3e46744e9d.jpeg" style="width: 8rem;margin: 20px 1rem">
  <img class="article" src="../../assets/ne/5f614276b4655f23bcd022b4_LZEJDU6FINHHVMPEU5ETHK5LOA.jpeg" style="width: 8rem;margin: 20px 1rem">
  <img class="article" src="../../assets/ne/5f751593b108e2261c09d9a5_NEMA_5109_NJP_05-NEW_web_080e88d62d9c4a1609dd2a1f51ff9250.jpeg" style="width: 8rem;margin: 20px 1rem">
  <img class="article" src="../../assets/ne/5f751593253c50950ce13d3f_NEMA_5109_NJP_10-NEW_web_1d97aa78be936f86af70ab3e46744e9d_1d97aa78be936f86af70ab3e46744e9d.jpeg" style="width: 8rem;margin: 20px 1rem">
  <div class="xz" style="display: flex;flex-direction: row;align-items: center;justify-content: center;font-size: 0.3rem;margin: 30px 0">
    RELATED PROJECTS
  </div>
  <div class="swiper mySwiper" style="margin-bottom: 30px">
    <div class="swiper-wrapper">
      <div class="swiper-slide" :key="index" v-for="(item,index) in list" @click="$emit('fclick',index)">
        <img :src="item.img1" style="width: 100%;height: 100%;object-fit: cover">
      </div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
  <footerB/>
</template>

<script>
import footerB from "@/components/footer";
import MainSize from '@/components/MainSize.vue'
export default {
  name: "list_2",
  components: {
    footerB,
    MainSize
  },
  data(){
    return {
      list:[
        {img1:require("../../assets/shop/City Hyde Park Apartments/g05b.jpg"),img2:require("../../assets/shop/City Hyde Park Apartments/g09b.jpg"),title:"City Hyde Park Apartments",msg:"5105 S Harper Ave, Chicago, IL 60615",type:true},
        {img1:require("../../assets/so/1.png"),img2:require("../../assets/so/2.png"),title:"Solstice on the Park Apartments",msg:"1616 E 56th St,Chicago,IL 60636",type:true},
        {img1:require("../../assets/shop/St. Regis Chicago/Sky-Longe_St.-Regis-Chicago-scaled.jpg"),img2:require("../../assets/shop/St. Regis Chicago/Sky-Terrace-2-scaled.jpg"),title:"St.Regis Chicago",msg:"363 E Wacker Dr, Chicago, IL 60601",type:true},
        {img1:require("../../assets/shop/700 N. 4th St/exterior-drone.jpg"),img2:require("../../assets/shop/700 N. 4th St/grove.jpg"),title:"700 N 4th St",msg:"Phoenix, AZ 85004",type:true},
        {img1:require("../../assets/image1/5.jpg"),img2:require("../../assets/image1/5.jpg"),title:"800 Broadway",msg:"San Diego, CA 92101",type:true},
        {img1:require("../../assets/image1/6.jpeg"),img2:require("../../assets/shop/Hampton Inn/9b8c7efa6150efcbd9d03eade785fe9a3ccc0705.jpeg"),title:"Hampton Inn & Suites Chicago-Burr Ridge",msg:"100 Harvester Dr,Burr Ridge,IL 60527",type:true},
        {img1:require("../../assets/image1/7.jpeg"),img2:require("../../assets/image1/7.jpeg"),title:"Four Points By Sheraton Milwaukee Airport",msg:"5311 S Howell Ave,Milwaukee,WI 53207",type:true},
        {img1:require("../../assets/shop/Hyatt Place Chicago/Hyatt-Place-Chicago-OHare-Airport-P004-Exterior-Shuttle.16x9.jpg"),img2:require("../../assets/shop/Hyatt Place Chicago/Hyatt-Place-Chicago-OHare-Airport-P016-Exterior-Outside-Views.16x9.jpg"),title:"Hyatt Place Chicago/O'Hare Airport",msg:"6810 Mannheim Road,Rosemont,IL 60018",type:true},
        {img1:require("../../assets/ne/WX20221010-183133.png"),img2:require("../../assets/ne/WX20221010-183212.png"),title:"NEMA Chicago",msg:"1210 S Indiana Ave, Chicago, IL 60605",type:true},
      ]
    }
  },
  mounted() {

    this.$nextTick(()=>{
      this.handleScroll();
      // eslint-disable-next-line no-undef
      new Swiper(".mySwiper", {
        loop:true,
        slidesPerView: 3,
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
      });
    })
  },
  methods:{
    handleScroll () {
      let scrollTop =  document.documentElement.scrollTop ;
      scrollTop=scrollTop+document.documentElement.clientHeight;
      let articles =  document.getElementsByClassName("article");
      for (let i = 0 ;i<articles.length ;i++){
        if(scrollTop>articles[i].offsetTop&&articles[i].style.opacity!=1){
          articles[i].style.opacity = 1;
        }
      }
    }
  }
}
</script>

<style scoped>
.swiper {
  width: 8rem;
  height: 1.5rem;
  margin: 0 1rem;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 990px){
  .t_img{
    width: 8rem !important;
  }
  .t_kuai{
    width: 8rem !important;
  }
  .t_title {
    font-size: 0.4rem !important;
  }
  .t_msg{
    font-size: 0.3rem !important;
  }
}
</style>