<template>
  <div style="height: 60px"></div>
  <div class="main2img article">
    <img src="../assets/img2.jpeg">
  </div>
  <div class="main2body article">
     <div class="timeLine xz" style="margin-bottom: 50px;font-family:'Lato'">OUR SERVICES</div>
    <div class="showFlex article" style="width: 8rem;flex-direction: row;justify-content: space-between;" :key="index" v-for="(tiem,index) in teamList">
      <div class="timelineItem">
        <div style="width: 2.75rem" v-show="index%2==0">
          <div class="xz timelineTitle" style="font-family:'Lato'">{{ tiem.name }}</div>
          <div class="timeLineFTitle">{{ tiem.t }}</div>
          <div class="timeLineMsg" v-html="tiem.m"></div>
        </div>
        <div class="timelineCircle" v-show="index%2==0">
          <img :src="tiem.img" style="width: 100%;height: 100%">
        </div>
      </div>
      <div style="width: 0.01rem;background: #e6c059;"></div>
      <div class="timelineItem">
        <div class="timelineCircle" v-show="index%2==1">
          <img :src="tiem.img" style="width: 100%;height: 100%">
        </div>
        <div style="width: 2.75rem" v-show="index%2==1">
          <div class="xz timelineTitle"  style="font-family:'Lato'">{{ tiem.name }}</div>
          <div class="timeLineFTitle">{{ tiem.t }}</div>
          <div class="timeLineMsg" v-html="tiem.m"></div>
        </div>
      </div>
    </div>

    <div class="noneFlex article" style="width: 8rem;flex-direction: column;justify-content: space-between;margin-top: 50px" :key="index" v-for="(tiem,index) in teamList">
      <div class="timelineItem">
        <div class="timelineCircle">
          <img :src="tiem.img" style="width: 100%;height: 100%"></div>
        <div style="width: 5.4rem">
          <div class="xz timelineTitle"  style="font-family:'Lato'">{{ tiem.name }}</div>
          <div class="timeLineFTitle">{{ tiem.t }}</div>
          <div class="timeLineMsg" v-html="tiem.m"></div>
        </div>
      </div>
    </div>

  </div>

  <footer-b/>
</template>

<script>
import footerB from './footer.vue'
export default {
  name: "main-2",
  components: {
    footerB
  },
  data(){
    return {
      teamList:[
        {img:require("../assets/image1/1.jpeg"),name:"General Construction",t:"",m:"21st Century is builders of general construction projects including: <br>" +
              "·New Construction <br>" +
              "·Renovations <br>" +
              "·Historic Renovations and Restorations"},
        {img:require("../assets/image1/21.png"),name:"Pre-construction Service",t:"",m:"We offer full range of pre-construction services including preliminary estimating and budgeting to help guide customers through the process."},
        {img:require("../assets/image1/3.jpg"),name:"Construction Management",t:"",m:"With 21st Century as your experienced and trustworthy Construction Manager, this construction approach can be one of the most cost effective and leads all parties coming out of the project successfully"},
        {img:require("../assets/image1/4.jpg"),name:"Financial Management",t:"",m:"Monitor, report on, and make key decisions to ensure the project meet financial goals."},
        {img:require("../assets/image1/5.jpg"),name:"Field & Safety Management",t:"",m:"Follow OSHA and our safety program to reduce the risk of workplace incidents, injuries, and fatalities through data-driven measurements and improvements."},
        {img:require("../assets/image1/6.jpeg"),name:"Construction Quality Control",t:"",m:"21st expertise will perform field inspection to ensure all the work performed/completed compliant with design."},
        {img:require("../assets/image1/7.jpeg"),name:"Post-Construction Service",t:"",m:"Perform all the final inspections and walkthrough, and training to handover the building entirely over to the building owner."},
        {img:require("../assets/image1/8.jpeg"),name:"Design/Build Construction",t:"",m:"21st Century expertise are responsible for designing, managing and building to provide building owner the most cost-effective and efficient ways to manage the project. \n"},

      ]
    }
  },
  mounted() {
    this.$nextTick(()=>{
      this.handleScroll();
    })
  },
  methods:{
    handleScroll () {
      let scrollTop =  document.documentElement.scrollTop ;
      scrollTop=scrollTop+document.documentElement.clientHeight;
      let articles =  document.getElementsByClassName("article");
      for (let i = 0 ;i<articles.length ;i++){
        if(scrollTop>articles[i].offsetTop&&articles[i].style.opacity!=1){
          articles[i].style.opacity = 1;
        }
      }
    }
  }
}
</script>


<style scoped>
.buttons{
  display: inline-block;
  padding:0.15rem 0.2rem;
  letter-spacing: .03rem;
  background: #e6c059;
  color: #fff;
  font-size: 0.2rem;
}
.timeLineMsg{
  font-size: 0.14rem;
  line-height: 0.21rem;
  color: #333;
}
.timeLineFTitle{
  font-size: 0.14rem;
  font-weight: 700;
  color: #333;
  margin: 5px 0;
}
.timelineCircle{
  background: #e6c059;border-radius: 0.8rem;width: 0.8rem;height: 0.8rem;margin: 0 0.2rem;
  overflow: hidden;
}
.timelineCircle >img{
  object-fit: cover;
}
.timelineTitle{  font-weight: 700;
  font-size: 0.2rem;letter-spacing: 0.03rem
}
.timelineItem{
  width: 3.95rem;display: flex;flex-direction: row;padding: 10px 0;
  align-items: center;


}

@media (max-width: 990px){
  .title{
    font-size: 0.2rem !important;
  }
  .f_title{
    font-size: 0.45rem !important;
  }
  .msg{
    font-size: .3rem !important;
    line-height: .5rem !important;
  }
  .timeLine{
    font-size: .45rem !important;
  }
  .timeLineFTitle{
    font-size: 0.2rem;
    font-weight: 700;
  }
  .timelineTitle{
    font-size: 0.5rem !important
  }
  .timeLineMsg{
    font-size: 0.2rem;
    line-height: 0.4rem;
  }
  .timelineTitle{
    font-size: 0.4rem;
  }
  .timeLineMsg{
    line-height: 0.4rem;
  }
  .timelineItem{
    width: 7.9rem;display: flex;flex-direction: row;padding: 10px 0;
    align-items: center;
  }
  .timelineCircle{
    background: #e6c059;border-radius: 2rem;width: 2rem;height: 2rem;margin: 0 0.2rem
  }
}
.timeLine{
  font-size: 0.18rem;
  letter-spacing: .05rem;
  font-weight: 700;
  margin-top: 50px;
}
.main2img{
  height: 450px;
  background: #42b983;
}
.main2img > img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.main2body{
  margin:60px 1.5rem;
  width: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title{
  font-size: 0.15rem;
  text-transform: uppercase;
  letter-spacing: .05rem;
}
.f_title{
  text-align: center;
  letter-spacing: .03rem;
  font-size: 0.3rem;
  color: #ccad60;
  margin: 20px 0;
}
.msg{
  font-size: 0.14rem;
  color: #333;
  margin-bottom: 30px;
}
.main2content{
  background-image: linear-gradient(180deg,hsla(0,0%,95.1%,.9),
  hsla(0,0%,95.1%,.9)),
  url("../assets/image1/21.png");
  background-size: cover;
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>